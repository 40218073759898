import React from "react";
import VerticalCard from "ps-components/Card/VerticalCard.jsx";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import productListStyle from "ps-assets/jss/productListStyle.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Layout from "ps-components/Layout/Layout.jsx";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Hidden from "@material-ui/core/Hidden";
import PortabilityForm from "templates/PortabilityPlansForm";



class PortabilityPlans extends React.Component {
  state = {
    recommendedPlans: [],
    signupModal: false,
    insuranceProduct: ``,
  };
  updateSignUpModal = (x) => {
    this.setState(x);
  }

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.updateSignUpModal(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.updateSignUpModal(x);
  }
  getDefaultRecommendedPlans = () => {
    const { data } = this.props;
    return data.allStrapiInsuranceplans.edges
      .filter((plan) => plan.node.isPortabilityProduct === true )
  };
  componentWillMount = () => {
    this.setState({ recommendedPlans: this.getDefaultRecommendedPlans() });
  };
  displayEmiRibbon = (productId) => {
    switch(productId){
      case 26:
      case 3:
      case 6:
      case 25:
      case 14:
      case 11:
      case 4:
      case 2:
      case 7:
      case 8:
      case 37:
      case 33:
      case 34:
      case 40:
      case 41:
      case 42:
      case 43:
        return true;
      default: return false;
    }
  }
  render() {
    const { data, classes } = this.props;
    const categoryData = data.strapiInsuranceplancategories
    const recommendedPlans = this.state.recommendedPlans
    return (
      <Layout
        image2={
          categoryData &&
          categoryData.bannerImage2 &&
          categoryData.bannerImage2.childImageSharp.fluid
        }
        small={true}
        title={'Health Insurance Portability'}
        summary={'Below are a few recommended plans to choose from. These are curated based on the popular choices made by policy holders while porting to Star Health Insurance.'}
      >
        <Helmet key="helmetTags">
          <title>Star Health Insurance Portability - Recommended Plans</title>
        </Helmet>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.section}>
            <div className={classes.container}>
              <Hidden mdUp>
                <h1 className={classes.pageTitle}>Health Insurance Portability</h1>
                <p className={classes.summary} style={{ fontSize: "17px" }}>
                  Below are a few recommended plans to choose from. These are curated based on the popular choices made by policy holders while porting to Star Health Insurance.
                </p>
              </Hidden>
              {recommendedPlans && recommendedPlans.length > 0 && (
                <>
                  <GridContainer>
                    <GridItem md={12} sm={12}>
                      <h2 className={classes.description}>Recommended Plans</h2>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    {recommendedPlans.map((document) => {
                      let image = document.node.thumbnailImages && (
                        <img
                          src={document.node.thumbnailImages.publicURL}
                          width="60"
                          height="60"
                          style={{ boxShadow: `none` }}
                        />
                      );
                      let action = null;
                      return (
                          <GridItem
                            key={document.node.id}
                            md={4}
                            lg={4}
                            sm={12}
                          >
                              <div
                          onClick={() => {this.handleClickOpen(`signupModal`); this.setState({ insuranceProduct: { name: [document.node.planType] , id: [document.node.productId], formType: `portabilityPlans` } })}}
                          className={classes.planButton}
                        >
                            <VerticalCard
                              displayComponent={
                                this.displayEmiRibbon(
                                  document.node.productId
                                ) && (
                                  <img
                                    src={require(`assets/img/emi-plan-transparent.png`)}
                                    className={classes.emiLabel}
                                  />
                                )
                              }
                              onHoverEffect
                              title={document.node.recommendationLabel ? document.node.recommendationLabel : document.node.name}
                              subtitle={document.node.recommendationLabel && document.node.name}
                              image={image}
                              action={action}
                              description={document.node.summary}
                              shareable={true}
                              plain
                              alignItems="center"
                            />
                            
                        </div>
                          </GridItem>
                      );
                    })}
                  </GridContainer>
                </>
              )}
            </div>
          </div>
        </div>
        <PortabilityForm handleClose={(modal) => this.handleClose(modal)} signupModal={this.state.signupModal} insuranceProduct={this.state.insuranceProduct}/>
      </Layout>
    );
  }
}

PortabilityPlans.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    location: PropTypes.object,
    pageContext: PropTypes.object
  };
  
export default withStyles(productListStyle)(PortabilityPlans);

export const portabilityPlansQuery = graphql`
query portabilityPlansList {
    allStrapiInsuranceplans(
      filter: { isVisible: { eq: true } }
    ) {
      edges {
        node {
          id
          productId
          name
          urlPath
          summary
          category
          planType
          isRecommended
          recommendationLabel
          isWithdrawn
          redirectUrl
          sequence
          isVisiblelist
          isPortabilityProduct
          thumbnailImages {
            publicURL
          }
        }
      }
    }
    strapiInsuranceplancategories(category: { in: ["health"] } ) {
        name
        summary
        category
        url
        colorCode
        titleTag
        metaTitle
        metaKeywords
        metaDescription
        bannerImage2 {
          publicURL
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bannerImage3 {
          publicURL
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bannerImage4 {
          publicURL
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
    }
}
`