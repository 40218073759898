import React from "react";
import Input from "@material-ui/core/Input";
import PropTypes from "prop-types";
import Card from "components/Card/Card.jsx";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import CustomSnackbar from "components/Snackbar/Snackbar";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "components/CustomButtons/Button.jsx";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import moment from "moment";
import GridItem from "components/Grid/GridItem.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import Radio from "@material-ui/core/Radio";
import Datetime from "react-datetime";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Close from "@material-ui/icons/Close";
import customStaticPageStyle from "ps-assets/jss/customStaticPageStyle.jsx";
import root from "window-or-global";
import FormHelperText from "@material-ui/core/FormHelperText";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import { lmsRequestCall } from "util/lmsRequest";
import { browserLocalStorage,browserWindow } from "util/localStorageHelper";
var apiBaseUrl = process.env.API_URL;
const portabiltyDays = 58;
const CUSTOMERPORTAL_URL = process.env.CUSTOMERPORTAL_URL

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const notIndividualPlans = [3]
const notFloaterPlans = [2]

class PortabilityForm extends React.Component{
  
  constructor(props) {
    super(props);
    this.state = {
      signupModal: false,
      selectedEnabled: ``,
      checkPolicyPeriod: ``,
      name: ``,
      mobile: ``,
      email: ``,
      policy_expiry_date: ``,
      product_type: ``,
      same_insurer: `yes`,
      submitStatus: ``,
      submitButton: false,
      cases: ``,
      dateValidation: false,
      urlInfo: ``,
    };
  }

  handleChange = e => {
    if (e.target.id === `mobile`) {
      if (!isNaN(e.target.value) && e.target.value.length <= 10) {
        this.setState({ [e.target.id]: e.target.value });
      }
    } else if (e.target.id === `name`) {
      if (e.target.value.search(`^[a-zA-Z. ']*$`) === 0) {
        this.setState({ [e.target.id]: e.target.value });
      }
    } else {
      this.setState({ [e.target.id]: e.target.value });
    }
  };

  handleChangeEnabled = event => {
    this.setState({ selectedEnabled: event.target.value });
  };

  handlePolicyPeriodCheck = (event) => {
    this.setState({
      checkPolicyPeriod: event.target.value
    });
  };

  handleDate = value => {
    this.handleDateValidation(value, this.state.cases);
    this.setState({ policy_expiry_date: value });
  };
  
  handleDateValidation = (value, cases) => {
    var endDate = moment()
      .add(portabiltyDays + 1, `d`)
      .format(`MM/DD/YYYY`);
    if (
      cases === `medical` &&
      moment(value).isBetween(
        moment()
          .add(30, `d`)
          .format(`MM/DD/YYYY`),
        endDate
      )
    ) {
      this.setState({
        dateValidation: true
      });
    } else if (
      cases === `non_medical` &&
      moment(value).isBetween(
        moment()
          .add(0, `d`)
          .format(`MM/DD/YYYY`),
        endDate
      )
    ) {
      this.setState({
        dateValidation: true
      });
    } else {
      this.setState({
        dateValidation: false
      });
    }
  };

  handleSubmitStatus = () => {
    this.setState({
      submitStatus: ``
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    let url = `${apiBaseUrl}form/portability`;
    let {
      name,
      mobile,
      email,
      policy_expiry_date,
      product_type,
      same_insurer,
      selectedEnabled,
      urlInfo
    } = this.state;
    this.setState({
      submitButton: true
    });

    let lmsRequestBody = {
      firstName: name,
      mobileNumber: mobile,
      email: email,
      source: `Portability`,
      urlInfo: this.state.urlInfo
    };

    let data = {
      name: name,
      mobile: mobile,
      email: email,
      policy_expiry_date: policy_expiry_date
        ? policy_expiry_date.format(`DD/MM/YYYY`)
        : ``,
      product_type: product_type.includes(`Senior`)
        ? `Senior Citizen`
        : product_type,
      same_insurer: selectedEnabled,
      urlInfo: urlInfo
    };
    let params = {
      method: `POST`,
      cache: `no-cache`,
      credentials: `same-origin`,
      headers: {
        "Content-Type": `application/json`
      },
      body: JSON.stringify(data)
    };
    this.handleDateValidation(policy_expiry_date, this.state.cases);

    if (
      name.length !== 0 &&
      (mobile.length !== 0 && mobile.length === 10) &&
      email.length !== 0 &&
      product_type.length !== 0 &&
      same_insurer !== 0 &&
      // this.state.dateValidation &&
      // this.state.selectedEnabled == `yes` &&
      this.state.selectedEnabled.length !== 0 && 
      this.state.checkPolicyPeriod == `same` &&
      // this.state.selectedEnabled == `no` ? this.state.checkPolicyPeriod == `same` : true &&
      // policy_expiry_date.length !== 0 &&
      (((product_type == `Individual` || product_type == `Floater`)))
    ) {
      lmsRequestCall(lmsRequestBody);
      let res = await fetch(url, params);
      let resJson = await res.json();
      if (res.status !== 200) {
        this.setState({
          submitStatus: `error`
        });
        this.handleClose(`signupModal`);
        // && resJson.status.includes(`Thank you, your request to port your policy has been received`
      } else if (
        resJson &&
        resJson.status.includes(
          `Thank you, your request to port your policy has been received`
        )
      ) {
        let redirectUrl;
        browserLocalStorage.setItem(`sessionalive`,true);
        const insuranceProduct = this.props.insuranceProduct ? this.props.insuranceProduct : `comprehensive`
        switch (product_type) {
          case `Individual`:
            redirectUrl = `${CUSTOMERPORTAL_URL}/customerportal/normalbuy?type=${insuranceProduct.name}&portability=true&adults=1&kids=0&policyCover=500000&year=1&name=${this.state.name}&mobNo=${this.state.mobile}&email=${this.state.email}&productId=${insuranceProduct.id}`;
            break;
          case `Floater`:
            redirectUrl = `${CUSTOMERPORTAL_URL}/customerportal/normalbuy?type=${insuranceProduct.name}&adults=2&kids=0&portability=true&policyCover=500000&year=1&name=${this.state.name}&mobNo=${this.state.mobile}&email=${this.state.email}&productId=${insuranceProduct.id}`;
            break;
        }
        redirectUrl += `&prevExpiryDate=${data.policy_expiry_date}`;
        root.open(redirectUrl, `_self`);
        this.setState({
          signupModal: false,
          selectedEnabled: `yes`,
          name: ``,
          mobile: ``,
          email: ``,
          policy_expiry_date: ``,
          product_type: ``,
          same_insurer: `yes`,
          submitStatus: `success`,
          refreshCaptcha: true,
          submitButton: false,
          cases: ``,
          dateValidation: false
        });
        this.handleClose(`signupModal`);
      }
    }
  };

  handleSimple = (type, event) => {
    var cases = `non_medical`;
    if (type === `sum_insured`) {
      if (
        ((this.state.product_type === `Individual` ||
          this.state.product_type === `Floater`) &&
          event.target.value === `Yes`) ||
        this.state.product_type.includes(`Senior Citizen`)
      ) {
        cases = `medical`;
      } else if (
        (this.state.product_type === `Individual` ||
          this.state.product_type === `Floater`) &&
        event.target.value === `no`
      ) {
        cases = `medical`;
      }

      this.setState({ [event.target.name]: event.target.value, cases: cases });
      this.handleDateValidation(this.state.policy_expiry_date, cases);
    } else if (type === `senior`) {
      if (event.target.value.includes(`Senior Citizen`)) {
        cases = `non_medical`;
      }
      this.setState({ [event.target.name]: event.target.value, cases: cases });
      this.handleDateValidation(this.state.policy_expiry_date, cases);
    }
  };

  handleClose(key){
    if(this.props.handleClose)
      this.props.handleClose(key);
  }
  
  componentDidMount = async () => {
    if( this.state.urlInfo === ``) {
      this.setState({
        urlInfo: browserWindow.location.href
      })
    }
  };


  render(){
    const { classes } = this.props;
    var startDate =
      this.state.cases !== ``
        ? this.state.cases === `medical`
          ? 30
          : this.state.cases === `non_medical`
            ? 0
            : 0
        : 0;
    if(this.props.insuranceProduct) { startDate = 6; }
    var emailRegex = new RegExp(/^\S+@\S+\.\S+$/);
    var phoneno = new RegExp(
      /^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-/\s.]?[0-9]{4}$/
    );
    var dateValidtion = Datetime.moment().add(startDate, `day`);
    var dateValidtionDefault = Datetime.moment().add(portabiltyDays, `day`);
    function addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }
    var valid_default = function(current) {
      return (
        current.isAfter(dateValidtion) && current.isBefore(dateValidtionDefault)
      );
    };
    var valid_condition = function(current) {
      return (
        current.isAfter(dateValidtion) && current.isBefore(dateValidtionDefault)
      );
    };
    return(
    <>
    {this.state.submitStatus === `success` && (
      <CustomSnackbar
        open={true}
        duration={6000}
        status={`success`}
        message={`Successfully submitted! We will get in touch with you soon`}
        flag={this.handleSubmitStatus}
      />
    )}
    {this.state.submitStatus === `error` && (
      <CustomSnackbar
        open={true}
        duration={6000}
        status={`error`}
        message={`We are currently facing issues, please try again later`}
        flag={this.handleSubmitStatus}
      />
    )}
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal + ` ` + classes.modalSignup
      }}
      open={this.props.signupModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => this.handleClose(`signupModal`)}
      aria-labelledby="signup-modal-slide-title"
      aria-describedby="signup-modal-slide-description"
    >
      <Card plain className={classes.modalSignupCard}>
        <DialogTitle
          id="signup-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => this.handleClose(`signupModal`)}
            style={{
              color: `#000`,
              float: `right`
            }}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h3
            className={`${classes.cardTitle} ${classes.modalTitle}`}
            style={{ marginLeft: `10%` }}
          >
            Want to port your policy?
          </h3>
        </DialogTitle>
        <DialogContent
          id="signup-modal-slide-description"
          className={`${classes.modalBody} ${classes.marginmodal}`}
          style={{ marginLeft: `10%`, overflow: `hidden` }}
        >
          <GridContainer>
            <GridItem
              xs={12}
              sm={10}
              md={10}
              className={classNames(classes.main, classes.mrAuto)}
            >
              <h4 className={classes.socialTitle}>
                Fill in your detail below and we will get back to you
              </h4>
              <form className={classes.form} onSubmit={this.handleSubmit}>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                      labelText="Name *"
                      id="name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={
                        {
                          // required: true
                        }
                      }
                      onChange={this.handleChange}
                      value={this.state.name}
                      error={
                        this.state.submitButton &&
                        this.state.name.length === 0
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <FormControl fullWidth style={{ marginTop: `4%` }}>
                      <Datetime
                        timeFormat={false}
                        id="policy_expiry_date"
                        labelText="Previous policy expiry date"
                        inputProps={{
                          placeholder: `Previous policy expiry date *`,
                          readOnly: true
                          // required: true
                        }}
                        onChange={moment => this.handleDate(moment)}
                        value={this.state.policy_expiry_date}
                        isValidDate={
                          this.state.cases === ``
                            ? valid_default
                            : valid_condition
                        }
                        closeOnSelect={true}
                        minDate={addDays(new Date() ,7)}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                      labelText="Email ID *"
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={
                        {
                          // required: true,
                          // type: `email`
                        }
                      }
                      onChange={this.handleChange}
                      value={this.state.email}
                      error={
                        (this.state.submitButton && !this.state.email) ||
                        (this.state.email.length !== 0 &&
                          this.state.email.search(emailRegex) !== 0)
                      }
                    />
                    {this.state.email.length != 0 &&
                      this.state.email.search(emailRegex) !== 0 && (
                      <FormHelperText id="my-helper-text" error>
                          Email Id is invalid{` `}
                      </FormHelperText>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                      labelText="Phone Number *"
                      id="mobile"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={
                        {
                          // required: true,
                          // pattern: `[0-9]{10}`
                        }
                      }
                      onChange={this.handleChange}
                      value={this.state.mobile}
                      error={
                        (this.state.submitButton && !this.state.mobile) ||
                        (this.state.mobile.length != 0 &&
                          this.state.mobile &&
                          this.state.mobile.search(phoneno) !== 0)
                      }
                    />
                    {this.state.mobile.length != 0 &&
                      this.state.mobile.search(phoneno) !== 0 && (
                      <FormHelperText id="my-helper-text" error>
                          Mobile Number is Numeric{` `}
                      </FormHelperText>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl
                      className={classes.formControl}
                      error={
                        this.state.submitButton &&
                        this.state.product_type.length === 0
                      }
                      fullWidth
                    >
                      <InputLabel
                        htmlFor="product-type"
                        className={classes.selectLabel}
                      >
                        Product Type *
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={this.state.product_type || ``}
                        onChange={e => this.handleSimple(`senior`, e)}
                        style={{ display: `block` }}
                        inputProps={{
                          name: `product_type`,
                          id: `product_type`
                        }}
                        input={<Input id="name-error" />}
                      >
                        {this.props.insuranceProduct && !notIndividualPlans.includes(this.props.insuranceProduct.id[0]) && 
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value="Individual"
                        >
                          Individual
                        </MenuItem>}
                        {this.props.insuranceProduct && !notFloaterPlans.includes(this.props.insuranceProduct.id[0]) && 
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value="Floater"
                        >
                          Floater
                        </MenuItem>}
                      </Select>
                    </FormControl>
                  </GridItem>

                  <div style={{ marginLeft: `3%`, marginTop: `3%` }}>
                    Does all insured have insurance from the same insurer ?
                  </div>
                  <GridItem>
                    Yes
                    <Radio
                      checked={this.state.selectedEnabled === `yes`}
                      onChange={this.handleChangeEnabled}
                      value="yes"
                      name="same_insurer"
                      aria-label="YES"
                      icon={
                        <FiberManualRecord
                          className={classes.radioUnchecked}
                        />
                      }
                      checkedIcon={
                        <FiberManualRecord
                          className={classes.radioChecked}
                        />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot
                      }}
                    />
                    No
                    <Radio
                      checked={this.state.selectedEnabled === `no`}
                      onChange={this.handleChangeEnabled}
                      value="no"
                      name="same_insurer"
                      aria-label="NO"
                      icon={
                        <FiberManualRecord
                          className={classes.radioUnchecked}
                        />
                      }
                      checkedIcon={
                        <FiberManualRecord
                          className={classes.radioChecked}
                        />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot
                      }}
                    />
                    {/* {this.state.selectedEnabled === `no` &&
                      !(
                        (this.state.policy_expiry_date != 0 &&
                          this.state.cases != `` &&
                          !this.state.dateValidation) ||
                        (this.state.submitButton &&
                          this.state.policy_expiry_date.length === 0)
                      ) && (
                      <FormHelperText
                        id="my-helper-text"
                        // error
                        style={{ marginTop: 0, color: `orangered` }}
                      >
                          Please enter the policy date that expires first
                      </FormHelperText>
                    )} */}
                  </GridItem>
                  {/* {this.state.selectedEnabled === `no` &&  */}
                  <>
                  <div style={{ marginLeft: `3%` }}>
                  Whether all the insured policy expiry date are same?
                  </div>
                  <GridItem>
                    Yes
                    <Radio
                      checked={this.state.checkPolicyPeriod === `same`}
                      onChange={this.handlePolicyPeriodCheck}
                      value="same"
                      name="same_insurer"
                      aria-label="Same"
                      icon={
                        <FiberManualRecord
                          className={classes.radioUnchecked}
                        />
                      }
                      checkedIcon={
                        <FiberManualRecord
                          className={classes.radioChecked}
                        />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot
                      }}
                    />
                    No
                    <Radio
                      checked={this.state.checkPolicyPeriod === `different`}
                      onChange={this.handlePolicyPeriodCheck}
                      value="different"
                      name="same_insurer"
                      aria-label="Different"
                      icon={
                        <FiberManualRecord
                          className={classes.radioUnchecked}
                        />
                      }
                      checkedIcon={
                        <FiberManualRecord
                          className={classes.radioChecked}
                        />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot
                      }}
                    />
                    </GridItem>
                    </>
                    {/* } */}
                    {this.state.checkPolicyPeriod === `different` &&
                      !(
                        (this.state.policy_expiry_date != 0 &&
                          this.state.cases != `` &&
                          !this.state.dateValidation) ||
                        (this.state.submitButton &&
                          this.state.policy_expiry_date.length === 0)
                      ) && (
                      <FormHelperText
                        id="my-helper-text"
                        // error
                        style={{ marginTop: 0, color: `red` }}
                      >
                          Please contact the branch for further proccess
                      </FormHelperText>
                    )}
                    {this.state.selectedEnabled === `` &&
                      this.state.submitButton &&
                      !(
                        (this.state.policy_expiry_date != 0 &&
                          this.state.cases != `` &&
                          !this.state.dateValidation) ||
                        (this.state.submitButton &&
                          this.state.policy_expiry_date.length === 0)
                      ) && (
                      <FormHelperText
                        id="my-helper-text"
                        error
                        style={{ marginTop: 0 }}
                      >
                          Please select an option
                      </FormHelperText>
                    )}
                  {this.state.policy_expiry_date != 0 &&
                  this.state.selectedEnabled != `` &&
                    this.state.cases != `` &&
                    !this.state.dateValidation && (
                    <div
                      className={`${classes.mlAuto} ${classes.mrAuto} ${classes.textCenter}`}
                    >
                      <FormHelperText
                        id="my-helper-text"
                        error
                        style={{ paddinggLeft: `30%` }}
                      >
                          You are not eligible as Policy period is not
                          within portability window
                      </FormHelperText>
                    </div>
                  )}
                  {this.state.submitButton &&
                    this.state.policy_expiry_date.length === 0 && (
                    <div
                      className={`${classes.mlAuto} ${classes.mrAuto} ${classes.textCenter}`}
                    >
                      <FormHelperText
                        id="my-helper-text"
                        error
                        style={{ paddinggLeft: `30%` }}
                      >
                          please select Previous policy expiry date
                      </FormHelperText>
                    </div>
                  )}
                  {/* {this.state.submitButton &&
                    this.state.policy_expiry_date.length === 0 && (
                    <div
                      className={`${classes.mlAuto} ${classes.mrAuto} ${classes.textCenter}`}
                    >
                      <FormHelperText
                        id="my-helper-text"
                        error
                        style={{ paddinggLeft: `30%` }}
                      >
                          please select Previous policy expiry date
                      </FormHelperText>
                    </div>
                  )} */}
                </GridContainer>
                <div className={`${classes.textCenter}`}>
                  <Button
                    round
                    style={{ backgroundColor: `#35488a` }}
                    type={`submit`}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Card>
    </Dialog>
    </>
    );
  }
}

PortabilityForm.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  signupModal:PropTypes.bool,
  handleClose:PropTypes.func,
  insuranceProduct: PropTypes.object,
};


export default withStyles(customStaticPageStyle)(PortabilityForm);